// Kart.js
import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import KundeMarker from "./KundeMarker";

const Kart = () => {
    const kundeData = [
        //Kunder vi har i dag!
        {
            position: [59.9139, 10.7522],
            status: "Kunde",
            color: "green",
        },
        {
            position: [60.38114341090863, 5.206996003916297],
            status: "Størksen Rustfri Industri AS",
            color: "green",
        },
        {
            position: [60.052746149736386, 6.019513697726281],
            status: "Hellesøy Verft AS",
            color: "green",
        },
        {
            position: [59.64006316281343, 6.303899069314461],
            status: "Weldone AS",
            color: "green",
        },
        {
            position: [60.43987750641912, 5.190303730651028],
            status: "Alsaker Stål AS",
            color: "green",
        },
        {
            position: [60.27743697893611, 5.230800329652764],
            status: "Fana Stål AS",
            color: "green",
        },
        {
            position: [60.35321679487347, 5.114651609560104],
            status: "Weldtech AS",
            color: "green",
        },
        {
            position: [59.22018048811314, 11.183075204414322],
            status: "NIOS AS",
            color: "green",
        },
        {
            position: [59.12389139778421, 11.454619982509865],
            status: "Ernex AS",
            color: "green",
        },
        {
            position: [59.20718912091869, 10.306417013919576],
            status: "Vika Montering AS",
            color: "green",
        },
        {
            position: [59.27265442174387, 10.438888140188974],
            status: "Jarlsberg Bygg AS",
            color: "green",
        },
        {
            position: [67.30275435690277, 14.707280742475731],
            status: "Vikan Sveis AS",
            color: "green",
        },
        {
            position: [59.0913491964758, 5.91291501134387],
            status: "Tau Mek. Verksted",
            color: "green",
        },
        {
            position: [60.39159416422601, 11.208843169314463],
            status: "Gjersing Mekaniske Verksted AS",
            color: "green",
        },
        {
            position: [59.644140344609575, 11.325650655551135],
            status: "Baastad Mekaniske AS",
            color: "green",
        },
        {
            position: [63.771416993459056, 8.818678371114853],
            status: "Kystteknikk AS",
            color: "green",
        },
        {
            position: [60.83767686839148, 11.087903231708747],
            status: "T.L Mek AS",
            color: "green",
        },
        {
            position: [59.575524503274195, 11.202820726711883],
            status: "Askim Mekansike Verksted AS",
            color: "green",
        },
        {
            position: [60.00498325483402, 11.024008066172513],
            status: "Xylem Watersolution AS",
            color: "green",
        },
        {
            position: [60.656240725188106, 10.871227898203694],
            status: "Smitek AS",
            color: "green",
        },
        {
            position: [69.20800526758612, 19.285098596556047],
            status: "Pro Con AS",
            color: "green",
        },
        {
            position: [58.88669367304952, 9.360258726676028],
            status: "Kai Tveit AS",
            color: "green",
        },
        {
            position: [58.937039455659054, 12.278496891749414],
            status: "Fremek Service AB",
            color: "green",
        },
        {
            position: [60.780612284870344, 10.630443426775265],
            status: "Edvin Hansen AS",
            color: "green",
        },
        {
            position: [62.47311762923408, 6.35153891337291],
            status: "Møre Stål AS",
            color: "green",
        },
        {
            position: [63.78708267215284, 11.453507726937275],
            status: "Lafopa AS",
            color: "green",
        },
        {
            position: [66.32285769175685, 14.13953188474912],
            status: "Nord Industrirør AS",
            color: "green",
        },
        {
            position: [62.495734435390794, 11.234224126867103],
            status: "Mekanor AS",
            color: "green",
        },
        {
            position: [63.04842618006067, 9.214387698061316],
            status: "A Kvam AS",
            color: "green",
        },
        {
            position: [59.92800092176894, 10.852569169279592],
            status: "AS Balkongbygg",
            color: "green",
        },
        {
            position: [60.383904514090275, 5.312317126754336],
            status: "Endúr Maritime AS",
            color: "green",
        },
        {
            position: [61.87243826725684, 9.107315169162163],
            status: "Bakke Stålprodukter AS",
            color: "green",
        },
        {
            position: [66.32450834460116, 14.170126705683595],
            status: "Imtas AS",
            color: "green",
        },
        {
            position: [60.86321245217507, 11.26097484027263],
            status: "Sveen Mekaniske AS",
            color: "green",
        },
        {
            position: [63.52609469891961, 9.07379906925159],
            status: "Marine Metall AS",
            color: "green",
        },
        {
            position: [63.05492365583027, 7.6536423692259605],
            status: "OK Vedlikehold AS",
            color: "green",
        },
        {
            position: [60.89254659421654, 10.919121798365559],
            status: "Dagestad Mekaniske Verksted AS",
            color: "green",
        },
        {
            position: [59.534626286780856, 11.1651255253024],
            status: "NIM Stålbygg AS",
            color: "green",
        },
        {
            position: [59.58970843780921, 11.112897927152275],
            status: "Askim Stålprodukter AS",
            color: "green",
        },
        {
            position: [60.43614503304932, 10.510564598348369],
            status: "A Jenserud & Sønn AS",
            color: "green",
        },
        {
            position: [61.08515577474009, 6.584352808005173],
            status: "Sogneprodukt AS",
            color: "green",
        },
        {
            position: [62.578891876618094, 6.119744484935951],
            status: "Blindheim Mekaniske AS",
            color: "green",
        },
        {
            position: [62.59022554161286, 11.271111577930698],
            status: "VMEK AS",
            color: "green",
        },
        {
            position: [60.48285310945486, 5.2758398695147575],
            status: "Bygg og Ventilasjon AS",
            color: "green",
        },
        {
            position: [61.199338807582514, 7.217995002072795],
            status: "DACO Mekaniske AS",
            color: "green",
        },
        {
            position: [67.29740273322558, 14.404712961517886],
            status: "Beko Industriveksted AS",
            color: "green",
        },

        //Kunder på vent
        {
            position: [59.206443193072715, 10.44750252854247],
            status: "ABT Bygg AS",
            color: "yellow",
        },
        {
            position: [59.18869954271914, 9.621315415048661],
            status: "Skien Rustfri Industri AS",
            color: "yellow",
        },

        //Kunder som har sagt nei!
        {
            position: [59.940145399878595, 30.38569513614522],
            status: "Russland",
            color: "red",
        },
    ];

    return (
        <MapContainer
            center={[63.4305, 10.3951]}
            zoom={5}
            style={{ height: "100vh", width: "100%" }}
            preferCanvas={true}
        >
            <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {kundeData.map((kunde, index) => (
                <KundeMarker
                    key={index}
                    position={kunde.position}
                    status={kunde.status}
                    color={kunde.color}
                />
            ))}
        </MapContainer>
    );
};

export default Kart;
