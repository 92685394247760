import "./App.css";
import Kart from "./Kart";
import "leaflet/dist/leaflet.css";

function App() {
    return (
        <div className='App'>
            <header className='App-header'>
                <h1>
                    Kundeoversikt <br />
                    Weld IT
                </h1>
            </header>
            <main>
                <Kart />
            </main>
        </div>
    );
}

export default App;
